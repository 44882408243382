import React, { useEffect } from "react";
import "./LandingPreContent.css";

import pre_cont_img_0 from "../../../assets/landing-pre-content/0.png";
import pre_cont_img_1 from "../../../assets/landing-pre-content/1.png";
import pre_cont_img_2 from "../../../assets/landing-pre-content/2.png";
import pre_cont_img_3 from "../../../assets/landing-pre-content/3.png";
import pre_cont_img_4 from "../../../assets/landing-pre-content/4.png";
import pre_cont_img_5 from "../../../assets/landing-pre-content/5.png";
import pre_cont_img_6 from "../../../assets/landing-pre-content/6.png";

function LandingPreContent(props) {
	useEffect(() => {
		let ele1 = document.getElementById("pre_cont_img_1");
		let ele2 = document.getElementById("pre_cont_img_2");
		let ele3 = document.getElementById("pre_cont_img_3");
		let ele4 = document.getElementById("pre_cont_img_4");
		let ele5 = document.getElementById("pre_cont_img_5");
		// let ele6 = document.getElementById("pre_cont_img_6");

		ele1.style.opacity = 1 * props.displacement;
		ele2.style.opacity =
			ele3.style.opacity =
			ele4.style.opacity =
			ele5.style.opacity =
				0;
		if (props.displacement > 1) {
			ele2.style.opacity = 1 * (props.displacement - 1);
		}
		if (props.displacement > 2) {
			ele3.style.opacity = ele4.style.opacity =
				1 * (props.displacement - 2);
		}
		if (props.displacement > 3) {
			ele5.style.opacity = 1 * (props.displacement - 3);
		}
	}, [props.displacement]);

	return (
		<div className="landing-pre-content-container">
			<div className="landing-pre-content">
				<img src={pre_cont_img_0} alt="" id="pre_cont_img_0" />
				<img src={pre_cont_img_1} alt="" id="pre_cont_img_1" />
				<img src={pre_cont_img_2} alt="" id="pre_cont_img_2" />
				<img src={pre_cont_img_3} alt="" id="pre_cont_img_3" />
				<img src={pre_cont_img_4} alt="" id="pre_cont_img_4" />
				<img src={pre_cont_img_5} alt="" id="pre_cont_img_5" />
				<img src={pre_cont_img_6} alt="" id="pre_cont_img_6" />
			</div>
		</div>
	);
}

export default LandingPreContent;
