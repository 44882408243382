import React, { useState, useEffect } from "react";
import "./Courses.css";
import { useNavigate } from "react-router-dom";
// import course1 from "./../../assets/course1.png";
import { getCourses } from "./../../services/CourseService";
import { getSkies, getBrushes } from "../../services/SkiesAndBrushes";

export default function Courses() {
	const navigate = useNavigate();
	const [Courses, setCourses] = useState([]);
	const [Skies, setSkies] = useState([]);
	const [Brushes, setBrushes] = useState([]);

	const [MenuValueSelected, setMenuValueSelected] = useState(
		localStorage?.getItem("MenuValueSelected")
			? localStorage.getItem("MenuValueSelected")
			: "courses"
	);
	const [CourseMenu, setCourseMenu] = useState(
		<div className="CourseMenu_navBar" onClick={(e) => CourseMenu_Click(e)}>
			<div className="CourseMenu_selected">Courses</div>
			<span>{"|"}</span>
			<div>Skies</div>
			<span>{"|"}</span>
			<div>Brushes</div>
		</div>
	);

	const Cards = (n) =>
		[...Array(n)].map((e, i) => (
			<div className="card-is-loading" key={"card-" + i}>
				<div className="image"></div>
				<div className="content">
					<h3>{}</h3>
					<p></p>
				</div>
			</div>
		));
	// const [curretMenuItem, setCurretMenuItem] = useState(null);

	function CourseMenu_Click(e) {
		switch (e.target.innerText) {
			case "Courses":
				setMenuValueSelected("courses");
				localStorage.setItem("MenuValueSelected", "courses");
				break;
			case "Skies":
				setMenuValueSelected("skies");
				localStorage.setItem("MenuValueSelected", "skies");
				break;
			case "Brushes":
				setMenuValueSelected("brushes");
				localStorage.setItem("MenuValueSelected", "brushes");
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		const Account = localStorage?.getItem("currentAccount");
		const Token = localStorage?.getItem("accessToken");
		const Signature = localStorage?.getItem("signature");
		const toriiTier = localStorage?.getItem("toriiTier");

		// console.log("Courses: Tier: ",localStorage.getItem("toriiTier") );
		if (MenuValueSelected === "courses") {
			// console.log(MenuValueSelected);
			setCourseMenu(
				<div
					className="CourseMenu_navBar"
					onClick={(e) => CourseMenu_Click(e)}
				>
					<div className="CourseMenu_selected">Courses</div>
					<span>{"|"}</span>
					<div>Skies</div>
					<span>{"|"}</span>
					<div>Brushes</div>
				</div>
			);
			if (Courses.length === 0) {
				getCourses(Account, Token, Signature).then((res) => {
					for (const each of res) {
						let course_blocked = "";
						if (each[1]["is_released"] === false) {
							continue;
						}
						if (each[1]["tier"] > toriiTier) {
							course_blocked = " course_blocked";
						}
						setCourses((Courses) => {
							return [
								...Courses,
								<div
									className={"course" + course_blocked}
									key={each[0]}
									onClick={() => {
										navigate(
											"/Courses/" + each[1]["course_name"]
										);
									}}
								>
									<div
										className="course-tier"
										style={{
											backgroundColor: course_blocked
												? "#eb5757"
												: "#6fcf97",
										}}
									>
										{each[1]["tier"]
											? each[1]["tier"] + " Torii"
											: "Free"}{" "}
										<span className="course-price">
											{"$" + each[1]["price"]}
										</span>
									</div>
									<img
										src={each[1]["thumbnail_link"]}
										className="course-img"
										alt="Course Thumbnail"
									/>
									<div className="course-info">
										<h3 className="course-title">
											{each[1]["course_name"]}
										</h3>
										<div className="course-description">
											{each[1]["description"]}
										</div>
									</div>
								</div>,
							];
						});
					}
				});
			}
		} else if (MenuValueSelected === "skies") {
			// console.log(MenuValueSelected);
			setCourseMenu(
				<div
					className="CourseMenu_navBar"
					onClick={(e) => CourseMenu_Click(e)}
				>
					<div>Courses</div>
					<span>{"|"}</span>
					<div className="CourseMenu_selected">Skies</div>
					<span>{"|"}</span>
					<div>Brushes</div>
				</div>
			);
			if (Skies.length === 0) {
				getSkies(Account, Token, Signature).then((res) => {
					for (const each of res) {
						let course_blocked = "";
						if (each[1]["is_released"] === false) {
							continue;
						}
						if (each[1]["tier"] > toriiTier) {
							course_blocked = " course_blocked";
						}
						setSkies((Skies) => {
							return [
								...Skies,
								<div
									className={"course" + course_blocked}
									key={each[0]}
									onClick={() => {
										navigate(
											"/Skies/" +
												each[1]["skies_pack_name"]
										);
									}}
								>
									<div
										className="course-tier"
										style={{
											backgroundColor: course_blocked
												? "#eb5757"
												: "#6fcf97",
										}}
									>
										{each[1]["tier"]
											? each[1]["tier"] + " Torii"
											: "Free"}{" "}
										<span className="course-price">
											{"$" + each[1]["price"]}
										</span>
									</div>
									<img
										src={each[1]["thumbnail_link"]}
										className="course-img"
										alt="Course Thumbnail"
									/>
									<div className="course-info">
										<h3 className="course-title">
											{each[1]["skies_pack_name"]}
										</h3>
										<div className="course-description">
											{each[1]["description"]}
										</div>
									</div>
								</div>,
							];
						});
					}
				});
			}
		} else if (MenuValueSelected === "brushes") {
			// console.log(MenuValueSelected);
			setCourseMenu(
				<div
					className="CourseMenu_navBar"
					onClick={(e) => CourseMenu_Click(e)}
				>
					<div>Courses</div>
					<span>{"|"}</span>
					<div>Skies</div>
					<span>{"|"}</span>
					<div className="CourseMenu_selected">Brushes</div>
				</div>
			);
			if (Brushes.length === 0) {
				getBrushes(Account, Token, Signature).then((res) => {
					for (const each of res) {
						let course_blocked = "";
						if (each[1]["is_released"] === false) {
							continue;
						}
						if (each[1]["tier"] > toriiTier) {
							course_blocked = " course_blocked";
						}
						setBrushes((Brushes) => {
							return [
								...Brushes,
								<div
									className={"course" + course_blocked}
									key={each[0]}
									onClick={() => {
										navigate(
											"/Brushes/" +
												each[1]["brushes_pack_name"]
										);
									}}
								>
									<div
										className="course-tier"
										style={{
											backgroundColor: course_blocked
												? "#eb5757"
												: "#6fcf97",
										}}
									>
										{each[1]["tier"]
											? each[1]["tier"] + " Torii"
											: "Free"}{" "}
										<span className="course-price">
											{"$" + each[1]["price"]}
										</span>
									</div>
									<img
										src={each[1]["thumbnail_link"]}
										className="course-img"
										alt="Course Thumbnail"
									/>
									<div className="course-info">
										<h3 className="course-title">
											{each[1]["brushes_pack_name"]}
										</h3>
										<div className="course-description">
											{each[1]["description"]}
										</div>
									</div>
								</div>,
							];
						});
					}
				});
			}
		}
	}, [MenuValueSelected]);

	return (
		<div className="CoursesContainer">
			{/* <h1>Promises Made, Promises Kept.</h1>  */}

			{/* <div className="secHead">Courses & Tools</div> */}

			<div className=".secHead">
				{CourseMenu}
				<hr />
			</div>
			{MenuValueSelected === "courses" &&
				(Courses.length === 0 ? (
					<div className="loading-cards">{Cards(9)}</div>
				) : (
					<div className="coursentool">{Courses}</div>
				))}
			{MenuValueSelected === "skies" &&
				(Skies.length === 0 ? (
					<div className="loading-cards">{Cards(9)}</div>
				) : (
					<div className="coursentool">{Skies}</div>
				))}
			{MenuValueSelected === "brushes" &&
				(Brushes.length === 0 ? (
					<div className="loading-cards">{Cards(9)}</div>
				) : (
					<div className="coursentool">{Brushes}</div>
				))}
		</div>
	);
}
