export async function getAccessToken() {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT + "auth/addr_jwt",
			{
				// Adding method type
				method: "POST",

				// Adding body or contents to send
				body: JSON.stringify({
					wallet_address:
						"0x365dec04f17811caa43373f134263168c9fc1235",
				}),

				// Adding headers to the request
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function verifySign(wallet_address, signature, access_token) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	// console.log(Token);
	// console.log('API ENDPOINT: ',process.env.REACT_APP_API_ENDPOINT);
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT + "auth/verifySign",
			{
				method: "POST",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log("error: ", error);
	}
}
