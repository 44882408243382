import React, { useState, useEffect, useRef } from "react";
import "./Composition.css";

import { useNavigate, useParams } from "react-router-dom";

// import compimg from "./../../assets/composition.png";

import { getCourseNameModules } from "./../../services/CourseService";
import {
	getBrushPackNameModules,
	getSkiesPackNameModules,
} from "./../../services/SkiesAndBrushes";

function Composition() {
	const { coursetitle } = useParams();
	const location_path = window.location.href.split("/");
	const CourseNtoolPath = location_path[location_path.length - 2];
	let navigate = useNavigate();
	const display = useRef(0);
	const [courseInfo, setCourseInfo] = useState({});
	const [modules, setModules] = useState([]);
	const [moduleList, setModuleList] = useState([]);
	const ReqToriiNum = useRef(0);

	const [toriiTier, setTier] = useState(localStorage?.getItem("toriiTier"));

	useEffect(() => {
		// console.log("Composition: setmodules");

		window.scrollTo(0, 0);
		// if (window.sessionStorage.getItem("courseloaded") === coursetitle) {
		// 	console.log(
		// 		JSON.parse(window.sessionStorage.getItem("currentCourse"))
		// 	);
		// }
		const Account = localStorage?.getItem("currentAccount");
		const Token = localStorage?.getItem("accessToken");
		const Signature = localStorage?.getItem("signature");

		console.log(CourseNtoolPath);
		if (CourseNtoolPath.toLowerCase() === "courses") {
			getCourseNameModules(coursetitle, Account, Token, Signature)
				.then((res) => {
					if (res.statusCode && res.statusCode === 401) {
						return;
					}

					window.sessionStorage.setItem("courseloaded", coursetitle);
					window.sessionStorage.setItem(
						"currentCourse",
						JSON.stringify(res)
					);
					setCourseInfo((courseInfo) => res["course_info"]);
					setModules(res["modules"]);
				})
				.catch((error) => console.log(error));
		} else if (CourseNtoolPath.toLowerCase() === "skies") {
			getSkiesPackNameModules(
				coursetitle,
				Account,
				Token,
				Signature
			).then((res) => {
				// console.log(res);
				if (res.statusCode && res.statusCode === 401) {
					return;
				}
				window.sessionStorage.setItem("courseloaded", coursetitle);
				window.sessionStorage.setItem(
					"currentCourse",
					JSON.stringify(res)
				);
				setCourseInfo((courseInfo) => res["course_info"]);
				setModules(res["modules"]);
			});
		} else if (CourseNtoolPath.toLowerCase() === "brushes") {
			getBrushPackNameModules(
				coursetitle,
				Account,
				Token,
				Signature
			).then((res) => {
				// console.log(res);
				if (res.statusCode && res.statusCode === 401) {
					return;
				}
				window.sessionStorage.setItem("courseloaded", coursetitle);
				window.sessionStorage.setItem(
					"currentCourse",
					JSON.stringify(res)
				);
				setCourseInfo((courseInfo) => res["course_info"]);
				setModules(res["modules"]);
			});
		}
	}, []);

	useEffect(() => {
		if (!isNaN(parseInt(courseInfo["tier"]))) {
			ReqToriiNum.current = parseInt(courseInfo["tier"]) - toriiTier;
			// console.log("required: ", ReqToriiNum.current);
		}
	}, [courseInfo, toriiTier]);

	useEffect(() => {
		if (modules.length <= 0) {
			return;
		}

		modules.forEach((module) => {
			const lessons = [];
			module[2].forEach((lesson) => {
				lessons.push(
					<div
						className={
							ReqToriiNum.current > 0
								? "lesson No-access"
								: "lesson"
						}
						onClick={() => {
							if (ReqToriiNum.current > 0) {
								return;
							}
							let lessonPath =
								"/" +
								CourseNtoolPath +
								"/" +
								coursetitle +
								"/" +
								lesson[1]["title"];
							navigate(lessonPath);
						}}
						key={lesson[1]["title"]}
					>
						<div className="lesson-title">
							Lesson {lesson[0]}: {lesson[1]["title"]}
						</div>
						<div className="lesson-time">
							{lesson[1]["duration"]}
						</div>
					</div>
				);
			});
			setModuleList((moduleList) => [
				...moduleList,
				<div
					className="course-module"
					key={"module" + module[0]}
					onClick={() => {
						if (display.current !== 0) {
							window.document
								.getElementById("module" + display.current)
								.style.setProperty(
									"display",
									"none",
									"important"
								);
						}
						if (display.current === parseInt(module[0])) {
							window.document.getElementById(
								"module-ext" + module[0]
							).innerHTML = "+";
							display.current = 0;
						} else {
							window.document
								.getElementById("module" + module[0])
								.style.setProperty(
									"display",
									"block",
									"important"
								);
							window.document.getElementById(
								"module-ext" + module[0]
							).innerHTML = "-";
							if (display.current) {
								// console.log("module-ext" + display.current);
								window.document.getElementById(
									"module-ext" + display.current
								).innerHTML = "+";
							}
							display.current = parseInt(module[0]);
						}
					}}
				>
					<div className="module-title">{module[1]["title"]}</div>
					<div className="module-end">
						<div className="module-lessons">
							{module[2].length} Lessons
						</div>
						<div
							className="module-ext"
							id={"module-ext" + module[0]}
						>
							+
						</div>
					</div>
				</div>,
				<div
					className="expand-course"
					id={"module" + module[0]}
					style={{ display: "none" }}
					key={"moduleExt" + module[0]}
				>
					{lessons}
				</div>,
			]);
		});
	}, [modules]);

	const compositionPage = (
		<div className="comp_container">
			<div className="location">
				<div
					className="nav-pop"
					onClick={() => {
						navigate("/Courses");
					}}
				>
					{"Courses & Tools"}
				</div>
				<span className="white">&nbsp;&gt;&nbsp;</span>
				{coursetitle}
			</div>
			<div className="comp-title">{coursetitle}</div>
			{ReqToriiNum.current > 0 && (
				<div className="No-access-buy-link">
					You need {ReqToriiNum.current} more Torii
					{ReqToriiNum.current > 1 ? "s" : ""} to access this course.
					Buy {ReqToriiNum.current > 1 ? "them " : "it "}
					<a
						href="https://opensea.io/collection/toriiscapes"
						target="_blank"
						rel="noreferrer"
					>
						here
					</a>
					.
				</div>
			)}
			{!modules || modules.length === 0 ? (
				<div className="comp-course-loading">
					<div className="image"></div>
					<div className="desc">
						<p></p>
					</div>
				</div>
			) : (
				<div className="comp-course-desc">
					<img
						src={courseInfo["thumbnail_link"]}
						className="comp-course-img"
						alt="course thumbnail"
					/>
					<div className="comp-desc-cont">
						<div className="comp-course-description">
							{courseInfo["description"]}
						</div>
						{/* {courseInfo["main_site_link"] && (
							<a
								className="clickformore"
								href={courseInfo["main_site_link"]}
								target="_blank"
								rel="noreferrer"
							>
								Click For More Details...
							</a>
						)} */}
					</div>
				</div>
			)}

			<div className="downloadtools">
				Course Files:{" "}
				<span>
					{(!!courseInfo["zip_link"]) ? (
						<a
							href={
								ReqToriiNum.current > 0
									? "#"
									: courseInfo["zip_link"]
							}
							className={
								ReqToriiNum.current > 0 ? "No-access" : ""
							}
						>
							download link
						</a>
					) : (
						(!!courseInfo["jpeg_download_link"])?
						(
							<>
							<a
								href={
									ReqToriiNum.current > 0
										? "#"
										: courseInfo["jpeg_download_link"]
								}
								className={
									ReqToriiNum.current > 0 ? "No-access" : ""
								}
							>
								JPEGS
							</a>{" "}
							{" | "}{" "}
							<a
								href={
									ReqToriiNum.current > 0
										? "#"
										: courseInfo["tiff_download_link"]
								}
								className={
									ReqToriiNum.current > 0 ? "No-access" : ""
								}
							>
								TIFFS
							</a>
						</>
						):(
							<a href={"#"}>{""}</a>
						)
					)}
				</span>{" "}
			</div>

			<div className="course-content-heading">Course Content</div>

			{modules.length === 0 ? (
				<>
					<div className="course-module loading" />
					<div className="course-module loading" />
					<div className="course-module loading" />
				</>
			) : (
				<div>{moduleList}</div>
			)}
		</div>
	);

	return compositionPage;
}

export default Composition;
