import React from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";

import twitterImg from "./../../assets/twitterlogo.png";
import discordImg from "./../../assets/discordlogo.png";
import openseaImg from "./../../assets/opensealogo.svg";
import toriiscapesImg from "./../../assets/toriilogo.png";

function Header(props) {
	let navigate = useNavigate();
	return (
		<>
			<div className="mobile-display">
				<img
					className="ToriiLogo-mobile"
					src={toriiscapesImg}
					alt="toriiscapes"
				/>
				<div className="shrine-social-mobile">
					<a
						href="https://discord.gg/rNMzttCnCZ"
						target="_blank"
						rel="noreferrer"
					>
						<img src={discordImg} alt="discord" />
					</a>
					<a
						href="https://opensea.io/collection/toriiscapes"
						target="_blank"
						rel="noreferrer"
					>
						<img src={openseaImg} alt="opensea" />
					</a>
					<a
						href="https://twitter.com/ToriiScapes"
						target="_blank"
						rel="noreferrer"
					>
						<img src={twitterImg} alt="twitter" />
					</a>
				</div>
			</div>
			<div className="desktop-display Header" id="header">
				<div className="shrine-head">
					<div className="shrine-social">
						<a
							href="https://discord.gg/rNMzttCnCZ"
							target="_blank"
							rel="noreferrer"
						>
							<img src={discordImg} alt="discord" />
						</a>
						<a
							href="https://opensea.io/collection/toriiscapes"
							target="_blank"
							rel="noreferrer"
						>
							<img src={openseaImg} alt="opensea" />
						</a>
						<a
							href="https://twitter.com/ToriiScapes"
							target="_blank"
							rel="noreferrer"
						>
							<img src={twitterImg} alt="twitter" />
						</a>
					</div>

					<img
						className="ToriiLogo"
						src={toriiscapesImg}
						alt="toriiscapes"
						onClick={() => {
							navigate("/Courses");
						}}
						style={{ cursor: "pointer" }}
					/>

					<div>
						{props.LoggedIn ? (
							<button
								className="btn-shrine"
								onClick={() => {
									props.disconnect();
								}}
							>
								Disconnect Wallet
							</button>
						) : (
							<button
								className="btn-shrine"
								onClick={() => {
									// Loader effect in ShrineLanding Page
									if (window.ethereum) {
										document.getElementById(
											"connect-loader"
										).style.display = "block";
										document.getElementById(
											"connect-msg"
										).innerHTML =
											"Step 1/2 : Connect to Metamask...";
										document
											.getElementById("LandingPage")
											.classList.add("is-blurred");
									}
									props.connect().then((res) => {
										if (res && props.LoggedIn) {
											navigate("/Courses");
										}
									});
								}}
							>
								Enter The Shrine
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;
