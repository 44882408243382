import React from "react";
import "./About.css";

function About() {
	return (
		<div className="landing-about-page">
			<h1>About</h1>
			<p>
				With ToriiScapes, Julius (<a href="https://www.instagram.com/visualsofjulius/" target="_blank" rel="noreferrer">@visualsofjulius</a>) is pushing the
				boundaries of what "generative art" can look and feel like.
				<br />
				<br />
				Come join us on this journey where we build systems that inspire
				you to learn.
			</p>
			<h1>ART</h1>
			<p>
				17 Explorers, 20 Skies, 17 Torii gates, 16 Grounds, 14 Hill
				Elements, 17 Landmarks, and 11 Air Elements complete Toriiscapes
				giving us millions of possible combinations. <br />
				<br />
				We also have one-of-a-kind signature pieces.
			</p>
			<h1>The Shrine Platform</h1>
			<p>
				Holding Toriies gives you access to Julius courses on the Shrine
				platform. Simple. <br/><br/> In this information abundant and
				attention-deficit world, we want to redefine how people learn
				online by shamelessly and unreservedly using every trick in
				behavioural science to empower you to outsmart yourself.<br/><br/>
				Building the Shrine is a journey, and it starts with a humble
				website of course videos.
			</p>
		</div>
	);
}

export default About;
