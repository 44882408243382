import React, { useEffect, useState } from "react";
import "./LandingPage.css";

import LandingPreContent from "../LandingPageSections/LandingPreContent/LandingPreContent";

import HolderTiers from "../LandingPageSections/HolderTiers/HolderTiers";
import ColumnerGallery from "../LandingPageSections/ColumnerGallery/ColumnerGallery";
import ShrineBuilders from "../LandingPageSections/ShrineBuilders/ShrineBuilders";
import About from "../LandingPageSections/About/About";
import Roadmap from "../LandingPageSections/Roadmap/Roadmap";

import shrineHome from "./../../assets/shrine_home.jpeg";
import Julius from "./../../assets/shrine-builder-pics/Julius/Julius.jpeg";
import Ruthvik from "./../../assets/shrine-builder-pics/Ruthvik/Ruthvik.jpeg";
import Bharath from "./../../assets/shrine-builder-pics/Bharath/Bharath.jpeg";

function LandingPage() {
	const [clickSection, setClickSection] = useState(0);
	const [staticSidebar, setStaticSidebar] = useState([]);
	const [Disp, setDisp] = useState(0);
	const navElements = [
		["Home", "#39ff14"],
		["About", "#ff3131"],
		["Holder Tiers", "#1589ff"],
		["Roadmap", "#ff9d36"],
		["Shrine Builders", "#15f4ee"],
	];

	const [scrollSection, setScrollSection] = useState(0);

	useEffect(() => {
		const header = document.getElementById("header");
		header.classList.add("static-header");

		window.addEventListener("scroll", ScrollNavChange);

		setStaticSidebar([]);

		for (let i = 0; i < navElements.length; i++) {
			setStaticSidebar((staticSidebar) => [
				...staticSidebar,
				<li
					onClick={() => {
						setClickSection(i);
						if (i !== scrollSection) {
							window.scrollTo(
								0,
								clickSection * window.innerHeight
							);
						}
					}}
					key={"navE" + i}
					className={clickSection === i ? "selected-landing-nav" : ""}
					style={
						clickSection === i ? { color: navElements[i][1] } : {}
					}
				>
					{navElements[i][0]}
				</li>,
			]);
		}

		return () => {
			window.removeEventListener("scroll", ScrollNavChange);
			header.classList.remove("static-header");
		};
	}, []);

	useEffect(() => {
		// console.log("scrollSection", scrollSection);

		setStaticSidebar([]);

		for (let i = 0; i < navElements.length; i++) {
			setStaticSidebar((staticSidebar) => [
				...staticSidebar,
				<li
					onClick={() => {
						setClickSection(i);
						if (i !== scrollSection) {
							window.scrollTo(
								0,
								clickSection * window.innerHeight
							);
						}
					}}
					key={"navE" + i}
					className={
						scrollSection === i ? "selected-landing-nav" : ""
					}
					style={
						scrollSection === i ? { color: navElements[i][1] } : {}
					}
				>
					{navElements[i][0]}
				</li>,
			]);
		}
	}, [scrollSection]);

	useEffect(() => {
		// console.log("clickSection: ", JSON.stringify(clickSection));
		window.scrollTo(0, clickSection * window.innerHeight);
	}, [clickSection]);

	const ScrollNavChange = () => {
		const displacement = (window.scrollY / window.innerHeight) % 100;
		setDisp(displacement);
		setScrollSection(Math.round(displacement));
	};

	return (
		<>
			<div className="mobile-display">
				<h3 className="use-desktop-alert">
					! Please use desktop for the <br />
					Shrine Experience
				</h3>
				<img src={shrineHome} alt="" className="shrine-home-mobile" />
				<div>
					<div>
						<h2>About</h2>
						<p>
							With ToriiScapes, Julius (
							<a
								href="https://www.instagram.com/visualsofjulius/"
								target="_blank"
								rel="noreferrer"
							>
								@visualsofjulius
							</a>
							) is pushing the boundaries of what "generative art"
							can look and feel like.
							<br />
							<br />
							Come join us on this journey where we build systems
							that inspire you to learn.
						</p>
						<div className="separator-about-mobile"></div>
					</div>
					<div>
						<h2>ART</h2>
						<p>
							17 Explorers, 20 Skies, 17 Torii gates, 16 Grounds,
							14 Hill Elements, 17 Landmarks, and 11 Air Elements
							complete Toriiscapes giving us millions of possible
							combinations. <br />
							<br />
							We also have one-of-a-kind signature pieces.
						</p>
						<div className="separator-about-mobile"></div>
					</div>
					<div>
						<h2>The Shrine Platform</h2>
						<p>
							Holding Toriies gives you access to Julius courses
							on the Shrine platform. Simple. <br />
							<br /> In this information abundant and
							attention-deficit world, we want to redefine how
							people learn online by shamelessly and unreservedly
							using every trick in behavioural science to empower
							you to outsmart yourself.
							<br />
							<br />
							Building the Shrine is a journey, and it starts with
							a humble website of course videos.
						</p>
						<div className="separator-about-mobile"></div>
					</div>
				</div>
				<div className="Team">
					<h2>Team</h2>
					<div>
						<a
							href="https://www.instagram.com/visualsofjulius/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={Julius}
								alt=""
								className="ToriiTeammate-mob"
							/>
						</a>
						<h2>Julius</h2>
						<p>Artist</p>
						<a
							href="https://www.instagram.com/ruthviking17/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={Ruthvik}
								alt=""
								className="ToriiTeammate-mob"
							/>
						</a>
						<h2>Ruthvik</h2>
						<p>Product Manager</p>
						<a
							href="https://www.instagram.com/bharath_reddy_2904/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={Bharath}
								alt=""
								className="ToriiTeammate-mob"
							/>
						</a>
						<h2>Bharath</h2>
						<p>Developer</p>
					</div>
				</div>
			</div>
			<div className="desktop-display landing-page" id="LandingPage">
				<div className="static-landing-sidebar-left">
					<LandingPreContent displacement={Disp} />
				</div>
				<div className="flex-dummy"></div>
				<div className="landing-content">
					<div className="landing-sections" id="home">
						<ColumnerGallery />
					</div>

					<hr />

					<div className="landing-sections" id="about">
						<About />
					</div>

					<hr />

					<div className="landing-sections" id="holder-tiers">
						<HolderTiers />
					</div>

					<hr />

					<div className="landing-sections" id="roadmap">
						<Roadmap />
					</div>

					<hr />

					<div className="landing-sections" id="shrine-builders">
						<h1>Shrine Builders</h1>
						<ShrineBuilders />
					</div>
				</div>
				<div className="flex-dummy"></div>
				<div className="static-landing-sidebar-right">
					<div className="landing-nav-container">
						<ul className="landing-nav">{staticSidebar}</ul>
						<div className="landing-nav-dummy"></div>
					</div>
				</div>
			</div>

			<div id="connect-loader" className="modal">
				<div className="loader"></div>
				<h2 id="connect-msg">{}</h2>
			</div>
		</>
	);
}

export default LandingPage;
