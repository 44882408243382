import React, { useEffect, useState, useRef } from "react";
import "./Lesson.css";

import { useNavigate, useParams } from "react-router-dom";
import { getCourseNameModules } from "./../../services/CourseService";
import {
	getBrushPackNameModules,
	getSkiesPackNameModules,
} from "../../services/SkiesAndBrushes";

function Lesson() {
	const navigate = useNavigate();
	const { coursetitle, lesson_name } = useParams();
	const location_path = window.location.href.split("/");
	const CourseNtoolPath = location_path[location_path.length - 3];
	const lesson_num = useRef("");
	const num_of_lessons = useRef(0);
	const lesson_video_src = useRef("");

	const [lessonlinks, setlessonlinks] = useState({});

	const [courseInfo, setCourseInfo] = useState({});
	const [modules, setModules] = useState([]);
	const [sidebar, setSidebar] = useState(null);
	const [sidebarToggle, setSidebarToggle] = useState(false);
	const Tier = useRef(
		parseInt(
			localStorage?.getItem("toriiTier")
				? localStorage.getItem("toriiTier")
				: 0
		)
	);

	const mediaPlayer = (
		<div className="iframeContainer">
			<iframe
				// src="https://player.vimeo.com/video/712300499?h=4c3ae97ef5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
				src={
					"https://player.vimeo.com/video/" +
					lesson_video_src.current[0] +
					"?h=" +
					lesson_video_src.current[1] +
					"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
				}
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				// allowFullScreen
				height="100%"
				width="80%"
				// style="position:absolute;top:0;left:0;width:100%;height:100%;"
				title="Why Starting YouTube Is So Hard-1080p"
				className="video"
			></iframe>
		</div>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		// if (window.sessionStorage.getItem("courseloaded") === coursetitle) {
		// 	console.log(
		// 		JSON.parse(window.sessionStorage.getItem("currentCourse"))
		// 	);
		// }
		const Account = localStorage?.getItem("currentAccount");
		const Token = localStorage?.getItem("accessToken");
		const Signature = localStorage?.getItem("signature");
		// console.log("Lesson: ToriiTier: ",Tier);
		if (CourseNtoolPath.toLowerCase() === "courses") {
			getCourseNameModules(coursetitle, Account, Token, Signature).then(
				(res) => {
					// console.log(res);
					// window.localStorage.setItem("courseloaded", coursetitle);
					// window.localStorage.setItem("currentCourse", JSON.stringify(res));

					// console.log("C-Lesson: ToriiTier: ",Tier);

					if (!isNaN(parseInt(res["course_info"]["tier"]))) {
						if (
							parseInt(res["course_info"]["tier"]) > Tier.current
						) {
							navigate("/" + CourseNtoolPath + "/" + coursetitle);
						}
					}
					setCourseInfo((courseInfo) => res["course_info"]);
					setModules(res["modules"]);
				}
			);
		} else if (CourseNtoolPath.toLowerCase() === "skies") {
			getSkiesPackNameModules(
				coursetitle,
				Account,
				Token,
				Signature
			).then((res) => {
				// console.log(res);
				// window.localStorage.setItem("courseloaded", coursetitle);
				// window.localStorage.setItem("currentCourse", JSON.stringify(res));
				if (!isNaN(parseInt(res["course_info"]["tier"]))) {
					if (parseInt(res["course_info"]["tier"]) > Tier.current) {
						navigate("/" + CourseNtoolPath + "/" + coursetitle);
					}
				}
				setCourseInfo((courseInfo) => res["course_info"]);
				setModules(res["modules"]);
			});
		} else if (CourseNtoolPath.toLowerCase() === "brushes") {
			getBrushPackNameModules(
				coursetitle,
				Account,
				Token,
				Signature
			).then((res) => {
				// console.log(res);
				// window.localStorage.setItem("courseloaded", coursetitle);
				// window.localStorage.setItem("currentCourse", JSON.stringify(res));
				if (!isNaN(parseInt(res["course_info"]["tier"]))) {
					if (parseInt(res["course_info"]["tier"]) > Tier.current) {
						navigate("/" + CourseNtoolPath + "/" + coursetitle);
					}
				}
				setCourseInfo((courseInfo) => res["course_info"]);
				setModules(res["modules"]);
			});
		}
	}, [coursetitle, navigate]);

	useEffect(() => {
		setSidebar(
			<ul className="sidebarModuleList">
				<li>
					Course Files:{" "}
					{/* <a className="linkcolor" href={courseInfo["zip_link"]}>
						download link
					</a> */}
					{!!courseInfo["zip_link"] ? (
						<a href={courseInfo["zip_link"]} className="linkcolor">
							download link
						</a>
					) : !!courseInfo["jpeg_download_link"] ? (
						<>
							<a
								href={courseInfo["jpeg_download_link"]}
								className="linkcolor"
							>
								JPEGS
							</a>{" "}
							{" | "}{" "}
							<a
								href={courseInfo["tiff_download_link"]}
								className="linkcolor"
							>
								TIFFS
							</a>
						</>
					) : (
						<a href={"#"}>{""}</a>
					)}
				</li>
				{modules.length === 0 &&
					[...Array(3)].map((e, i) => (
						<li key={"mod-li-load" + i} className="mod-loading">
							<div className="mod-title-loading"></div>
							<ul className="sublist">
								{[
									...Array(Math.floor(Math.random() * 2) + 3),
								].map((e1, i1) => (
									<li key={"sub-load" + i1}></li>
								))}
							</ul>
						</li>
					))}
				{modules.map((module) => {
					const lessons = [];
					module[2].forEach((lesson) => {
						num_of_lessons.current++;

						setlessonlinks((lessonlinks) => {
							return {
								...lessonlinks,
								[lesson[0]]:
									"/" +
									CourseNtoolPath +
									"/" +
									coursetitle +
									"/" +
									lesson[1]["title"],
							};
						});
						if (lesson_name === lesson[1]["title"]) {
							lesson_num.current = parseInt(lesson[0]);
							const vidloc = lesson[1]["link"].split("/");
							lesson_video_src.current = [
								vidloc.at(-2),
								vidloc.at(-1),
							];
							// console.log(lesson_video_src.current);
						}
						lessons.push(
							<li
								className={
									lesson[1]["title"] === lesson_name
										? "active-lesson"
										: "inactive-lesson"
								}
								key={"les" + lesson[0]}
								onClick={() => {
									let lessonPath =
										"/" +
										CourseNtoolPath +
										"/" +
										coursetitle +
										"/" +
										lesson[1]["title"];
									navigate(lessonPath);
								}}
							>
								<abbr title={lesson[1]["title"]}>
									{lesson[0]}. {lesson[1]["title"]}
								</abbr>
							</li>
						);
					});

					return (
						<li key={"mod" + module[0]}>
							{module[1]["title"]}
							<ul className="sublist" key={"mod-ul" + module[0]}>
								{lessons}
							</ul>
						</li>
					);
				})}
			</ul>
		);
	}, [courseInfo, modules, lesson_name, coursetitle, navigate]);

	return (
		<div>
			<div className="mediaContainer">
				<div className="sidebar" id="sidebar">
					<div className="sidebarheader">
						<div className="sidebartitle">Contents </div>
						<span
							className="toggler"
							id="toggler"
							onClick={() => {
								if (sidebarToggle) {
									document.getElementById(
										"toggler"
									).innerHTML = "";
									document
										.getElementById("sidebar")
										.classList.remove("closeSideBar");
									document
										.getElementById("sidebar")
										.classList.add("openSideBar");
									setSidebarToggle(false);
									setTimeout(() => {
										document.getElementsByClassName(
											"location_width"
										)[0].style.width = "70vw";
									}, 250);
									setTimeout(() => {
										document.getElementsByClassName(
											"lessonName"
										)[0].style.width = "50vw";
									}, 250);
									setTimeout(() => {
										document.getElementById(
											"toggler"
										).innerHTML = "<<";
									}, 400);
								} else {
									document.getElementById(
										"toggler"
									).innerHTML = ">>";
									// document.getElementById("sidebar").className="closeSideBar";
									document
										.getElementById("sidebar")
										.classList.remove("openSideBar");
									document
										.getElementById("sidebar")
										.classList.add("closeSideBar");
									setSidebarToggle(true);
									setTimeout(() => {
										document.getElementsByClassName(
											"location_width"
										)[0].style.width = "80vw";
									}, 250);
									setTimeout(() => {
										document.getElementsByClassName(
											"lessonName"
										)[0].style.width = "60vw";
									}, 250);
								}
							}}
						>
							&lt;&lt;
						</span>
					</div>
					<div className="horzln"></div>
					{sidebar}
				</div>
				<div className="lessonVideoholder">
					<div className="location location_width">
						<div
							className="nav-pop"
							onClick={() => {
								navigate("/Courses");
							}}
						>
							{"Courses & Tools"}
						</div>
						<span className="white">&nbsp;&gt;&nbsp;</span>
						<div
							className="nav-pop"
							onClick={() => {
								navigate(
									"/" + CourseNtoolPath + "/" + coursetitle
								);
							}}
						>
							{coursetitle}
						</div>
						<span className="white">&nbsp;&gt;&nbsp;</span>
						{lesson_name}
					</div>
					<div className="applyflexbet">
						<div className="lessonName">
							Lesson {lesson_num.current}: {lesson_name}
						</div>
						<div className="PrevNextCont">
							<span
								className="prev"
								onClick={() => {
									if (lesson_num.current !== 1) {
										navigate(
											lessonlinks[
												(lesson_num.current - 1) %
													Object.keys(lessonlinks)
														.length
											]
										);
									}
								}}
							>
								<span className="linkcolor">&lt;</span>
								&ensp;Previous
							</span>
							<span
								className="next"
								onClick={() => {
									navigate(
										lessonlinks[
											1 +
												(lesson_num.current %
													Object.keys(lessonlinks)
														.length)
										]
									);
								}}
							>
								Next&ensp;
								<span className="linkcolor">&gt;</span>
							</span>
						</div>
					</div>
					{lesson_num.current > 0 ? (
						mediaPlayer
					) : (
						<div className="video-player-loading"></div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Lesson;
