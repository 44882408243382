import { useState, useEffect } from "react";

import { injected } from "./components/wallet/connectors";
import { useWeb3React } from "@web3-react/core";

import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";

import Header from "./components/Header/Header";
import Protected from "./components/Protected/Protected";
import Courses from "./components/Courses/Courses";
// import ShrineLanding from "./components/ShrineLanding/ShrineLanding";
import Composition from "./components/Composition/Composition";
import Lesson from "./components/Lesson/Lesson";
import getContractABI from "./components/wallet/ContractABI";
import Web3 from "web3";

import { getAccessToken, verifySign } from "./services/AuthService";
import LandingPage from "./components/LandingPage/LandingPage";

function App() {
	const [count, setCount] = useState(0);

	const { active, account, activate, deactivate } = useWeb3React();

	const [LoggedIn, setLoggedIn] = useState(
		localStorage?.getItem("isWalletConnected") === "true" ? true : false
	);

	const [Tier, setTier] = useState(
		parseInt(localStorage?.getItem("toriiTier")) || 0
	);

	const [accessToken, setAccessToken] = useState(
		localStorage?.getItem("accessToken")
	);

	const [signature, setSignature] = useState(
		localStorage?.getItem("signature")
	);

	// get number of Toriis the account hold
	async function getNumTokens() {
		// console.log("App: getNumTokens");
		if (active) {
			// console.log(account);
			try {
				const contractAddress =
					"0x6c1f04e1f6ecad7db470b4ff8c40ac0590534a43";

				const contractABI = getContractABI().abi;

				const web3 = new Web3(Web3.givenProvider);

				const contract = new web3.eth.Contract(
					contractABI,
					contractAddress
				);

				const result = await contract.methods.balanceOf(account).call();

				// special status, need to remove this
				// if(account === '0x9b9d2fa4264020FB20Ba4ccBF28f7bB0dEc61aab'){
				// 	setTier(10);
				// 	return 10;
				// }

				setTier(parseInt(result));

				if (parseInt(result) >= 0) {
					return parseInt(result); //  && parseInt(result) > 0
				}

				return 0;
			} catch (ex) {
				console.log(ex);
			}
		}
	}

	function getBrowserName() {
		const x = navigator.userAgent;
		if ((x.indexOf("Opera") || x.indexOf("OPR")) !== -1) {
			return "opera";
		} else if (x.indexOf("Edg") !== -1) {
			return "edge";
		} else if (x.indexOf("Chrome") !== -1) {
			return "chrome";
		} else if (x.indexOf("safari") !== -1) {
			return "safari";
		} else if (x.indexOf("Firefox") !== -1) {
			return "firefox";
		} else if (
			x.indexOf("MSIE") !== -1 ||
			!!document.documentMode === true
		) {
			return "IE";
		} else {
			return "unknown";
		}
	}

	async function connect() {
		console.log("App: connect");
		if (!window.ethereum) {
			alert(
				"Please add Metamask to your Browser...\n(Please reload if you've added the metamask extension.)"
			);
			switch (getBrowserName()) {
				case "chrome":
					window.open(
						"https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=chrome-ntp-icon",
						"_blank"
					);
					break;
				case "firefox":
					window.location = window.open(
						"https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
						"_blank"
					);
					break;
				default:
					break;
			}
			return false;
		}
		try {
			await activate(injected);
			return true;
		} catch (ex) {
			console.log(ex);
			return false;
		}
	}

	async function disconnect() {
		console.log("App: disconnect");
		try {
			deactivate();
			localStorage.setItem("isWalletConnected", false);
			localStorage.setItem("toriiTier", 0);
			setTier(0);
			setLoggedIn(false);
		} catch (ex) {
			console.log(ex);
		}
	}

	const signing_msg = () => {
		const ele = document.getElementById("connect-msg");
		if (ele) {
			ele.innerHTML = "Step 2/2 : Sign on Metamask...";
		}
	};

	// function to connect when reloaded
	useEffect(() => {
		// console.log(active, LoggedIn, count);
		const connectWalletOnPageLoad = async () => {
			if (localStorage?.getItem("isWalletConnected") === "true") {
				try {
					// console.log("reload useEffect.");
					await connect();
				} catch (ex) {
					console.log(ex);
				}
			}
		};
		connectWalletOnPageLoad();
	}, []);

	useEffect(() => {
		console.log("App: active: ", active);
		if (count) {
			if (active) {
				// console.log("in Active");
				signing_msg();
				getNumTokens().then((res) => {
					// console.log("Token res: ", res);
					const tempTier = res;
					// check if signature and token availabilty and verify it
					// if error get a new token and sign it
					if (signature && accessToken) {
						// verify available signature
						console.log("signature available...");
						verifySign(account, signature, accessToken)
							.then((response) => {
								if (response["statusCode"] === 401) {
									localStorage.setItem("toriiTier", 0);
									setLoggedIn(false);

									console.log("error: ", response);
									console.log(
										"signature invalid, getting new one..."
									);

									//  getting new token
									getAccessToken().then((res) => {
										// account switch load effect
										document.getElementById(
											"connect-loader"
										).style.display = "block";
										document.getElementById(
											"connect-msg"
										).innerHTML =
											"Step 2/2 : Sign on Metamask...";
										document
											.getElementById("LandingPage")
											.classList.add("is-blurred");

										console.log("token: ", res);
										setAccessToken(res["access_token"]);
										localStorage.setItem(
											"accessToken",
											res["access_token"]
										);

										//  signing the new token
										(async () => {
											const sign =
												await window.ethereum.request({
													method: "personal_sign",
													params: [
														res["access_token"],
														account,
														"",
													],
												});
											setSignature(sign);
											localStorage.setItem(
												"signature",
												sign
											);
											localStorage.setItem(
												"isWalletConnected",
												true
											);
											console.log("tempTier: ", tempTier);
											setTier(tempTier);
											localStorage.setItem(
												"toriiTier",
												tempTier
											);
											setLoggedIn(true);
										})();
									});
								} else {
									// valid signature  =>  allow login
									console.log("verified: ", response);
									localStorage.setItem(
										"isWalletConnected",
										true
									);
									setTier(tempTier);
									localStorage.setItem("toriiTier", tempTier);
									setLoggedIn(true);
								}
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						// if token or signature not available => get token and sign it
						console.log(
							"signature not available... getting new sign..."
						);
						localStorage.setItem("isWalletConnected", false);
						// localStorage.setItem("toriiTier", 0);
						setLoggedIn(false);
						console.log("account: ", account);

						// getting new token
						getAccessToken().then((res) => {
							console.log("token: ", res);
							setAccessToken(res["access_token"]);
							localStorage.setItem(
								"accessToken",
								res["access_token"]
							);

							// signing the new token
							(async () => {
								const sign = await window.ethereum.request({
									method: "personal_sign",
									params: [res["access_token"], account, ""],
								});
								setSignature(sign);
								localStorage.setItem("signature", sign);
								localStorage.setItem("isWalletConnected", true);
								// setTier(tempTier);
								setLoggedIn(true);
							})();
						});
					}
					// setLoggedIn(true);
				});
			} else {
				localStorage.setItem("isWalletConnected", false);
				localStorage.setItem("toriiTier", 0);
				setLoggedIn(false);
			}
		}
		setCount((count) => count + 1);
	}, [active, account]);

	useEffect(() => {
		console.log("App: Account: ", account);
		if (!account) {
			setTier(0);
		}
		if (active && account !== localStorage?.getItem("currentAccount")) {
			console.log(account, localStorage?.getItem("currentAccount"));
			localStorage.setItem("currentAccount", account);
			if (LoggedIn) {
				window.location.reload();
			}
		}
	}, [account]);

	useEffect(() => {
		console.log("App: Tier :", Tier);
		localStorage.setItem("toriiTier", Tier);
		// if (active && account !== localStorage?.getItem("currentAccount")) {
		// 	localStorage.setItem("currentAccount", account);
		// 	window.location.reload();
		// }
	}, [Tier]);

	return (
		// <div>
		// 	<ShrineLanding />
		// </div>
		<Router>
			<Header
				connect={connect}
				disconnect={disconnect}
				LoggedIn={LoggedIn}
			/>
			<Routes>
				<Route
					path="/"
					element={
						LoggedIn ? (
							<Navigate to="/Courses" />
						) : (
							// <ShrineLanding />
							<LandingPage />
						)
					}
				/>
				<Route
					path="/Courses"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Courses />
						</Protected>
					}
				/>
				<Route
					path="/Courses/:coursetitle"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Composition
								toriiTier={Tier}
								WalletAccount={account}
							/>
						</Protected>
					}
				/>
				<Route
					path="/Courses/:coursetitle/:lesson_name"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Lesson toriiTier={Tier} WalletAccount={account} />
						</Protected>
					}
				/>
				<Route
					path="/Skies/:coursetitle"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Composition
								toriiTier={Tier}
								WalletAccount={account}
							/>
						</Protected>
					}
				/>
				<Route
					path="/Skies/:coursetitle/:lesson_name"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Lesson toriiTier={Tier} WalletAccount={account} />
						</Protected>
					}
				/>
				<Route
					path="/Brushes/:coursetitle"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Composition
								toriiTier={Tier}
								WalletAccount={account}
							/>
						</Protected>
					}
				/>
				<Route
					path="/Brushes/:coursetitle/:lesson_name"
					element={
						<Protected isLoggedIn={LoggedIn}>
							<Lesson toriiTier={Tier} WalletAccount={account} />
						</Protected>
					}
				/>
				<Route path="*" element={<Navigate to="/Courses" />} />
			</Routes>
		</Router>
	);
}

export default App;

// useEffect(() => {
// 	injected
// 		.isAuthorized()
// 		.then((isAuthorized) => {
// 			setLoaded(true);
// 			if (isAuthorized && !active && !error) {
// 				activate(injected);
// 			}
// 		})
// 		.catch(() => {
// 			setLoaded(true);
// 		});
// }, [activate, active, error]);
