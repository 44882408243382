import React from "react";
import "./Roadmap.css";
import img1 from "./../../../assets/workinprogress.gif";

function Roadmap() {
	return (
		<div className="landing-roadmap">
			<h1>Roadmap</h1>
			<div>
				<img src={img1} alt="" />
				<p>Work in Progress!!!</p>
			</div>

			{/* <ul className="landing-roadmap-torii-count">
                <li>
                    718
                    <ul>
                        <li>Education Platform
                           <ul><li>Exclusive content</li></ul> 
                        </li>
                        <li>Launch of Torii Marketplace for building your own Torii and ordering prints
                        <ul><li>Will enable us to support the project till mint</li></ul> 
                        </li>
                        <li>Holder Signature Piece → Holder exclusive signature piece raffle</li>
                    </ul>
                </li>
                <li>
                    850: Project Relaunch
                    <ul>
                        <li>Enables us to ensure support to the project</li>
                        <li>Add more exclusive content and update existing content</li>
                    </ul>
                </li>
                <li>
                    2222
                    <ul>
                        <li>Trait re-roll for a fee</li>
                        <li>Discounts for Julius courses</li>
                        <li>Conduct holder exclusive photo editing contest and give exciting prizes to the winners
                            <ul><li>Similar Events on recurring basis</li></ul>
                        </li>
                    </ul>
                </li>
                <li>
                    5555
                    <ul>
                        <li>Behavioural psychology based nudging engine to encourage users to finish courses</li>
                        <li>Mobile Version of Shrine</li>
                    </ul>
                </li>
            </ul> */}
		</div>
	);
}

export default Roadmap;
