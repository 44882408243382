import React, { useEffect } from "react";
import "./ColumnerGallery.css";
import { gsap } from "gsap";

import img1 from "./../../../assets/landing-column-imgs/1.jpeg";
import img2 from "./../../../assets/landing-column-imgs/2.png";
import img3 from "./../../../assets/landing-column-imgs/3.png";
import img4 from "./../../../assets/landing-column-imgs/4.png";
import img5 from "./../../../assets/landing-column-imgs/5.png";
import img6 from "./../../../assets/landing-column-imgs/6.png";
import img7 from "./../../../assets/landing-column-imgs/7.png";
import img8 from "./../../../assets/landing-column-imgs/8.png";
import img9 from "./../../../assets/landing-column-imgs/9.jpeg";
import img10 from "./../../../assets/landing-column-imgs/10.png";
import img11 from "./../../../assets/landing-column-imgs/11.jpeg";
import img12 from "./../../../assets/landing-column-imgs/12.jpeg";

function ColumnerGallery() {
	useEffect(() => {
		console.clear();

		const additionalY = { val: 0 };
		let offset = 0;
		const cols = gsap.utils.toArray(".col");

		cols.forEach((col, i) => {
			const images = col.childNodes;

			// DUPLICATE IMAGES FOR LOOP
			images.forEach((image) => {
				var clone = image.cloneNode(true);
				col.appendChild(clone);
			});

			images.forEach((item) => {
				let columnHeight = item.parentElement.clientHeight;
				let direction = i % 2 !== 0 ? "+=" : "-="; // Change direction for odd columns

				// SET ANIMATION
				gsap.to(item, {
					y: direction + Number(columnHeight / 2),
					duration: 40,
					repeat: -1,
					ease: "none",
					modifiers: {
						y: gsap.utils.unitize((y) => {
							if (direction === "+=") {
								offset += additionalY.val;
								y =
									(parseFloat(y) - offset) %
									(columnHeight * 1);
							} else {
								offset += additionalY.val;
								y =
									(parseFloat(y) + offset) %
									-Number(columnHeight * 1);
							}

							return y;
						}),
					},
				});
			});
		});
	}, []);

	return (
		<div>
			<section className="section-tagline">
				<h1>World's first generative 3D landscapes</h1>
			</section>
			<div class="gallery">
				<div class="col">
					<div class="image">
						<img src={img1} alt="" />
					</div>
					<div class="image">
						<img src={img2} alt="" />
					</div>
					<div class="image">
						<img src={img3} alt="" />
					</div>
					<div class="image">
						<img src={img4} alt="" />
					</div>
				</div>
				<div class="col">
					<div class="image">
						<img src={img5} alt="" />
					</div>
					<div class="image">
						<img src={img6} alt="" />
					</div>
					<div class="image">
						<img src={img7} alt="" />
					</div>
					<div class="image">
						<img src={img8} alt="" />
					</div>
				</div>
				<div class="col">
					<div class="image">
						<img src={img9} alt="" />
					</div>
					<div class="image">
						<img src={img10} alt="" />
					</div>
					<div class="image">
						<img src={img11} alt="" />
					</div>
					<div class="image">
						<img src={img12} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ColumnerGallery;
