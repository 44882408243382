import React from "react";
import "./ShrineBuilders.css";

function ShrineBuilders() {
	return (
		<div>
			<div className="shrine-builders-container">
				<div className="shrine-builder">
					<div className="shrine-builders-card card0">
						<div class="shrine-builders-border">
							<h2 className="Torii-mate-name">Julius</h2>
							<div className="shrine-builders-icons">
								<a
									href="https://www.instagram.com/visualsofjulius/"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-instagram"
										aria-hidden="true"
									></i>
								</a>
								<a
									href="https://twitter.com/Visualsofjulius"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-twitter"
										aria-hidden="true"
									></i>
								</a>
								{/* <i className="fa fa-facebook" aria-hidden="true"></i> */}
							</div>
						</div>
					</div>
					<h2 className="Torii-role">Artist</h2>
				</div>
				<div className="shrine-builder">
					<div className="shrine-builders-card card1">
						<div className="shrine-builders-border">
							<h2 className="Torii-mate-name">Ruthvik</h2>
							<div className="shrine-builders-icons">
								<a
									href="https://www.instagram.com/ruthviking17/"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-instagram"
										aria-hidden="true"
									></i>
								</a>
								<a
									href="https://twitter.com/ruthviking17"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-twitter"
										aria-hidden="true"
									></i>
								</a>
								{/* <i className="fa fa-facebook" aria-hidden="true"></i> */}
							</div>
						</div>
					</div>
					<h2 className="Torii-role">Product Manager</h2>
				</div>
				<div className="shrine-builder">
					<div className="shrine-builders-card card2">
						<div className="shrine-builders-border">
							<h2 className="Torii-mate-name">Bharath</h2>
							<div className="shrine-builders-icons">
								<a
									href="https://www.instagram.com/bharath_reddy_2904/"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-instagram"
										aria-hidden="true"
									></i>
								</a>
								{/* <a
									href="https://www.youtube.com"
									target="_blank"
									rel="noreferrer"
								>
									<i
										className="fa fa-twitter"
										aria-hidden="true"
									></i>
								</a> */}
								{/* <i class="fa fa-facebook" aria-hidden="true"></i> */}
							</div>
						</div>
					</div>
					<h2 className="Torii-role">Developer</h2>
				</div>
			</div>
		</div>
	);
}

export default ShrineBuilders;
