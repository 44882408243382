export async function getCourses(wallet_address, access_token, signature) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT + "courses/",
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getCourseModules(
	courseID,
	wallet_address,
	access_token,
	signature
) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT +
				"courses/course_id/" +
				courseID,
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getCourseNameModules(
	courseName,
	wallet_address,
	access_token,
	signature
) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT +
				"courses/course_name/" +
				courseName,
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}
