export async function getSkies(wallet_address, access_token, signature) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT + "skies_pack/",
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getSkiesPackNameModules(
	skies_pack_name,
	wallet_address,
	access_token,
	signature
) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT +
				"skies_pack/skies_pack_name/" +
				skies_pack_name,
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}


export async function getBrushes(wallet_address, access_token, signature) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT + "brushes_pack/",
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}

export async function getBrushPackNameModules(
	brush_pack_name,
	wallet_address,
	access_token,
	signature
) {
	const Token = wallet_address + "/" + access_token + "/" + signature;
	try {
		const response = await fetch(
			process.env.REACT_APP_API_ENDPOINT +
				"brushes_pack/brushes_pack_name/" +
				brush_pack_name,
			{
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: "Bearer " + Token,
				},
			}
		);
		return await response.json();
	} catch (error) {
		console.log(error);
	}
}
