import React, { useEffect, useState } from "react";
import "./HolderTiers.css";

function HolderTiers() {
	const [tier, setTier] = useState(5);

	const [selectedSlide, setSelectedSlide] = useState(0);

	const Courseslist = {
		0: ["Introduction to Photoshop"],
		1: ["Glowing Wave Photo Composite", "Rivendell Photo Composite"],
		3: ["Photoshop For Photographers"],
		5: ["Sky Replacement Bootcamp"],
	};

	const SkiesList = {
		0: [],
		1: [],
		3: [
			[
				"Sky Bundle 1",
				[
					"Sunset Sky Pack",
					"Night Sky Pack",
					"Northern Lights Sky Pack",
					"Day Sky Pack",
				],
			],
		],
		5: [
			[
				"Sky Bundle 2",
				[
					"Sunset Sky Pack 2",
					"Night Sky Pack 2",
					"Day Sky Pack 2",
					"Moody Sky Pack",
				],
			],
		],
	};

	const BrushesList = {
		0: [],
		1: [],
		3: [["Brush Bundle 1", []]],
		5: [
			[
				"Mega Brush Bundle",
				[
					"Lanterns & Fireflies Pack",
					"Moons & Lightning Pack",
					"Clouds & Birds Packs",
					"Fog & Refining Pack",
					"Milky Ways Pack",
					"Dust, Snow & Splashes Pack",
					"Silhouettes & Waterfalls Pack",
					"Light Flares & Sun Rays Pack",
					"Grass, Hair & Fur Pack",
				],
			],
		],
	};

	const Perks = { 0: [], 1: [], 3: [], 4: [] };

	const ContList = ["Courses", "Brushes", "Sky_packs", "Perks"];

	const ContListItems = [Courseslist, BrushesList, SkiesList, Perks];

	const tierList = [0, 1, 3, 5];

	const tierSymbol = {
		0: "0",
		1: "1",
		3: "3",
		5: "5",
	};

	const tierMonetaryValues = [97, 551, 996, 1549];

	let tierArr = [];
	let slideArr = [];

	for (let each = 0; each < 4; each++) {
		slideArr.push(
			<div
				className="holder-tier-slide"
				onMouseOver={() => {
					setSelectedSlide(each);
				}}
			>
				<div className="holder-tier-slide-title">{ContList[each]}</div>
				<div className="holder-tier-slide-content">
					<ul></ul>
				</div>
			</div>
		);
		tierArr.push(
			<li
				className={
					tier === tierList[3 - each]
						? "holder-tiers-selector holder-tiers-selector-active"
						: "holder-tiers-selector"
				}
				onClick={() => {
					setTier(tierList[3 - each]);
				}}
			>
				<div>{3 - each ? tierList[3 - each] : "FREE"} </div>
				{3 - each ? (
					<span className="toriiSym" role="img" aria-label="torii">
						⛩️
					</span>
				) : (
					""
				)}
				<span className="tier-monetory">{ '$'+tierMonetaryValues[3 - each]}</span>
			</li>
		);
	}

	const [tierSelectors, setTierSelector] = useState(tierArr);

	const [slidesContent, setSlidesContent] = useState(slideArr);

	useEffect(() => {
		setSlidesContent([]);
		setTierSelector([]);
		for (let each = 0; each < 4; each++) {
			setSlidesContent((slidesContent) => {
				let slideContentList = [];
				if (each === 0) {
					for (let it of tierList) {
						for (let item of ContListItems[each][it]) {
							if (it <= tier) {
								slideContentList.push(
									<li>{tierSymbol[it] + " - " + item}</li>
								);
							} else {
								slideContentList.push(
									<li className="lower-tier-slide">
										{tierSymbol[it] + " - " + item}
									</li>
								);
							}
						}
					}
				} else if (each === 1) {
					for (let it of tierList) {
						// console.log(ContListItems[each][it]);
						for (let item of ContListItems[each][it]) {
							const nestItems = [];
							for (let nestItem of item[1]) {
								nestItems.push(<li>{nestItem}</li>);
							}
							if (it <= tier) {
								slideContentList.push(
									<>
										<li>
											{tierSymbol[it] + " - " + item[0]}
										</li>
										<ul className="holder-tier-slide-list-items">
											{nestItems}
										</ul>
									</>
								);
							} else {
								slideContentList.push(
									<>
										<li className="lower-tier-slide">
											{tierSymbol[it] + " - " + item[0]}
										</li>
										<ul className="holder-tier-slide-list-items lower-tier-slide">
											{nestItems}
										</ul>
									</>
								);
							}
						}
					}
				} else if (each === 2) {
					for (let it of tierList) {
						for (let item of ContListItems[each][it]) {
							const nestItems = [];
							for (let nestItem of item[1]) {
								nestItems.push(<li>{nestItem}</li>);
							}
							if (it <= tier) {
								slideContentList.push(
									<>
										<li>
											{tierSymbol[it] + " - " + item[0]}
										</li>
										<ul className="holder-tier-slide-list-items">
											{nestItems}
										</ul>
									</>
								);
							} else {
								slideContentList.push(
									<>
										<li className="lower-tier-slide">
											{tierSymbol[it] + " - " + item[0]}
										</li>
										<ul className="holder-tier-slide-list-items lower-tier-slide">
											{nestItems}
										</ul>
									</>
								);
							}
						}
					}
				} else {
					slideContentList.push(
						<div className="comsoon">Coming Soon!</div>
					);
				}
				return [
					...slidesContent,
					<div
						className={
							selectedSlide === each
								? "holder-tier-slide selected-slide"
								: "holder-tier-slide"
						}
						onMouseOver={() => {
							setSelectedSlide(each);
						}}
					>
						<div className="holder-tier-slide-title">
							{ContList[each]}
						</div>
						<div className="holder-tier-slide-content">
							<ul className="holder-tier-slide-content-list1">
								{slideContentList}
							</ul>
						</div>
					</div>,
				];
			});
			setTierSelector((tierSelectors) => {
				return [
					...tierSelectors,
					<li
						className={
							tier === tierList[3 - each]
								? "holder-tiers-selector holder-tiers-selector-active"
								: "holder-tiers-selector"
						}
						onClick={() => {
							setTier(tierList[3 - each]);
						}}
					>
						<div>{3 - each ? tierList[3 - each] : "FREE"} </div>
						{3 - each ? (
							<span className="toriiSym" role="img" aria-label="torii">
								⛩️
							</span>
						) : (
							""
						)}
						<span className="tier-monetory">{'$'+tierMonetaryValues[3 - each]}</span>
					</li>,
				];
			});
		}
	}, [tier, selectedSlide]);

	// useEffect(() => {
	// 	console.log(selectedSlide);
	// }, [selectedSlide]);

	return (
		<div className="holder-tiers">
			<h1>Holder Tiers</h1>
			<div className="holder-tiers-container">{slidesContent}</div>
			<ul className="holder-tiers-selectors-container">
				{tierSelectors}
			</ul>
		</div>
	);
}

export default HolderTiers;
